import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Headline, InfoPageContainer } from '../components';

import { black } from '../utils/sharedStyles';

const StyledLink = styled(Link)`
  color: ${black};
  margin: 1rem 0;
`;

const ErrorPage = () => (
  <InfoPageContainer>
    <Headline>Something went wrong.</Headline>
    <StyledLink to="/">Go back.</StyledLink>
  </InfoPageContainer>
);

export default ErrorPage;
